<template>
  <div class="my-info">
    <div class="my-info-container mt-5">
      <!-- <b-row>
              <b-col class="d-flex">
                  <h3> Мои данные </h3> 
                  <div>
                      <img src="images/school/edit.svg" alt="">
                      <span>Изменить</span>
                  </div>
              </b-col>
          </b-row> -->
      <!-- <div class="home-container"> -->
        <!-- <b-row>
          <b-col class="text-left">
            <p class="usernameStyle">{{userName.contractor.shortName}}</p>
          </b-col>
        </b-row> -->

      <b-row class="c-card-section">
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'info' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('myinfo') }}
                        </div> -->
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/myinfo.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("myinfo") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'Request' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('Request') }}
                        </div> -->
              <!-- <div class="mt-auto d-block" >
                            <b-img width="60px" src="images/design/arrow-right.svg" />
                        </div> -->
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/request.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("RequestInfo") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'inspector' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('Inspector') }}
                        </div>
                        <div class="mt-auto d-block" >
                            <b-img width="60px" src="images/design/arrow-right.svg" />
                        </div> -->
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/inspector.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("Inspector") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'controlfunction' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('ControlFunction') }}
                        </div>
                        <div class="mt-auto d-block" >
                            <b-img width="60px" src="images/design/arrow-right.svg" />
                        </div> -->
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/cfunction.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("ControlFunction") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'mandatoryrequirement' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('MandRequirement') }}
                        </div>
                        <div class="mt-auto d-block" >
                            <b-img width="60px" src="images/design/arrow-right.svg" />
                        </div> -->

              <div class="mt-auto d-block">
                <b-img
                  style="width: 100%"
                  src="images/design/requirement.svg"
                />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("MandRequirement") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'complaint' })"
          >
            <b-col>
              <!-- <div class="c-title">
                            {{ $t('Complaint') }}
                        </div>
                        <div class="mt-auto d-block" >
                            <b-img width="60px" src="images/design/arrow-right.svg" />
                        </div> -->
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/complaint.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("Complaint") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'inspectionbookofcontractor' })"
          >
            <b-col>
              <div class="mt-auto d-block">
                <b-img style="width: 90%" src="images/design/inpectionbook.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("inspectionbookAll") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
         <!-- <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'inspectionbook' })"
          >
            <b-col>
              <div class="mt-auto d-block">
                <b-img style="width: 90%" src="images/design/inspectionbook_1.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("inspectionbook") }}
              </div>
            </b-col>
          </b-row>
        </b-col> -->
        <!-- <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor: pointer"
            @click="$router.push({ name: 'News' })"
          >
            <b-col>
              
              <div class="mt-auto d-block">
                <b-img style="width: 100%" src="images/design/complaint.svg" />
              </div>
              <div
                style="font-size: 16px; text-align: center; margin-top: 10px"
              >
                {{ $t("News") }}
              </div>
            </b-col>
          </b-row>
        </b-col> -->
        <!-- <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor:pointer"
            @click="$router.push({ name: 'oked' })"
          >
            <b-col>
              <div class="mt-auto d-block">
                <b-img style="width:100%" src="images/design/complaint.svg" />
              </div>
              <div style="font-size: 16px;text-align: center; margin-top: 10px">
                {{ $t("oked") }}
              </div>
            </b-col>
          </b-row>
        </b-col> -->
         <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
          <b-row
            class="w-100"
            style="cursor:pointer"
            @click="$router.push({ name: 'videolesson' })"
          >
            <b-col>
              <div class="mt-auto d-block">
                <b-img style="width:90%" src="images/design/video_lesson.svg" />
              </div>
              <div style="font-size: 16px;text-align: center; margin-top: 10px">
                {{ $t("videolesson") }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          class="d-flex align-items-center c-my-col"
        >
           <a style="color:black;text-decoration: none" href="https://t.me/tekshiruvinfo_bot" target="_blank">
            <b-row
            class="w-100"
            style="cursor:pointer"
          >
            
            <b-col>
              <div class="mt-auto d-block">
                <b-img style="width:90%" src="images/design/telegram_bot.svg" />
              </div>
              <div style="font-size: 16px;text-align: center; margin-top: 10px">
                {{ $t("Telegram_Bot") }}
              </div>
            </b-col>
          </b-row>
           </a>
         
        </b-col>
      </b-row>
      <!-- </b-row> -->
      <b-modal
        v-model="contractorModal"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div
          style="
            text-align: right;
            margin-right: 10px;
            margin-top: -5px;
            margin-bottom: 5px;
            border-bottom: 1px solid lightgray;
          "
        >
          <span
            @click="contractorModal = false"
            style="cursor: pointer; font-size: 30px"
          >
            &times;
          </span>
        </div>
        <div>
          <custom-select
            :options="ContractorList"
            v-model="filter.contractorId"
            :label="$t('contractor')"
            :valueid="'tin'"
            @input="selectContractor()"
          ></custom-select>
        </div>
      </b-modal>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BImg, BModal, BButton } from "bootstrap-vue";
import AccountService from "../../services/account.service";
import customSelect from "../../components/elements/customSelect.vue";
import customButton from "../../components/elements/customButton.vue";
export default {
  data() {
    return {
      contractorModal: false,
      ContractorList: [],
      filter: {
        contractorId: 0,
      },
      username:""
    };
  },
  created() {
    this.userName =JSON.parse(localStorage.getItem('user_info'))
    if (!this.ContractorId) {
      this.contractorModal = true;
      AccountService.GetContractorsList().then((res) => {
        this.ContractorList = res.data;
      });
    }
  },
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    customSelect,
    customButton,
    BButton,
  },
  computed: {
    ContractorId() {
      return JSON.parse(localStorage.getItem("user_info")).contractorId;
    },
    IsTeacher() {
      return JSON.parse(localStorage.getItem("user_info")).IsTeacher;
    },
    IsHeadOfSchoolGrade() {
      return JSON.parse(localStorage.getItem("user_info")).IsHeadOfSchoolGrade;
    },
  },
  methods: {
    selectContractor() {
      if (!!this.filter.contractorId) {
        AccountService.SelectContractor(this.filter.contractorId)
          .then((res) => {
            localStorage.setItem("user_info", JSON.stringify(res.data));
            this.contractorModal = false;
          })
          .catch((error) => {
            this.makeToast(error.response.data, "error");
          });
      }
    },
  },
};
</script>

<style>

@media screen and (min-width: 992px) {
  .c-card-section .c-my-col .row .col {
    height: 200px !important;
  }
}
@media screen and (max-width: 700px) {
  .my-info-container {
    width: 100% !important;
  }
  .c-card-section .c-my-col .row .col {
    height: 160px !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 701px) {
  .my-info-container {
    width: 100% !important;
  }
  .c-title {
    font-size: 24px !important;
  }
  .c-card-section .c-my-col .row .col {
    height: 260px !important;
  }
}
@media screen and (max-width: 576px) {
  .my-info-container {
    width: 100% !important;
  }
  .c-title {
    font-size: 24px !important;
  }
  .c-card-section .c-my-col .row .col img {
    height: 120px;
  }
}
</style>
